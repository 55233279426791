import { Check, Database, Shield, RefreshCw, AlertCircle } from "lucide-react";

const Feapi = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100">
      <div className="w-full max-w-4xl p-8 bg-white rounded-2xl shadow-xl">
        <div className="text-center mb-8">
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            API de Facturación Electrónica
          </h1>
          <p className="text-gray-600">Sistema de gestión de facturación en tiempo real</p>
          
          <div className="flex items-center justify-center gap-2 mt-4">
            <div className="flex items-center bg-green-100 text-green-700 px-4 py-2 rounded-full">
              <Check size={18} className="mr-2" />
              <span className="font-semibold">Servicios Activos</span>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
          {/* Status Card */}
          <div className="bg-gradient-to-br from-green-50 to-emerald-50 p-6 rounded-xl shadow-sm border border-green-100">
            <div className="flex items-center gap-3 mb-4">
              <Check size={24} className="text-green-600" />
              <h2 className="text-lg font-semibold text-gray-800">Estado del Servicio</h2>
            </div>
            <p className="text-green-700 font-medium">Operando normalmente</p>
          </div>

          {/* Database Card */}
          <div className="bg-gradient-to-br from-blue-50 to-indigo-50 p-6 rounded-xl shadow-sm border border-blue-100">
            <div className="flex items-center gap-3 mb-4">
              <Database size={24} className="text-blue-600" />
              <h2 className="text-lg font-semibold text-gray-800">Base de Datos</h2>
            </div>
            <p className="text-blue-700 font-medium">Conectada y sincronizada</p>
          </div>

          {/* Security Card */}
          <div className="bg-gradient-to-br from-purple-50 to-violet-50 p-6 rounded-xl shadow-sm border border-purple-100">
            <div className="flex items-center gap-3 mb-4">
              <Shield size={24} className="text-purple-600" />
              <h2 className="text-lg font-semibold text-gray-800">Seguridad</h2>
            </div>
            <p className="text-purple-700 font-medium">Protección activa</p>
          </div>
        </div>

        <div className="flex flex-wrap justify-center gap-4 mt-8">
          <button className="flex items-center gap-2 bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-lg shadow-lg transition-all duration-200 transform hover:scale-105">
            <Check size={20} />
            Sistema Online
          </button>
          
          <button className="flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-lg shadow-lg transition-all duration-200 transform hover:scale-105">
            <RefreshCw size={20} />
            Actualizar Estado
          </button>
          
          <button className="flex items-center gap-2 bg-gray-600 hover:bg-gray-700 text-white px-6 py-3 rounded-lg shadow-lg transition-all duration-200 transform hover:scale-105">
            <AlertCircle size={20} />
            Reportar Problema
          </button>
        </div>
      </div>
    </div>
  );
};

export default Feapi;