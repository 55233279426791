import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"; // Importa el ícono de WhatsApp

const PaymentAlert = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <div className="bg-white rounded-xl shadow-2xl p-8 max-w-lg w-full relative transform transition-all duration-500 scale-100 hover:scale-105">
        {/* Ícono de advertencia */}
        <div className="flex justify-center mb-4">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-yellow-500 text-6xl"
          />
        </div>
        {/* Título de la alerta */}
        <h2 className="text-3xl font-bold text-gray-800 mb-4"> {/* Agrandamos el título */}
          Cuenta bloqueada
        </h2>
      
        <p className="text-lcdg text-gray-600 mb-6"> {/* Agrandamos el texto */}
          Parece que tu cuenta ha sido bloqueada debido a la falta de pago.
          Por favor, realiza el pago pendiente para reactivar tu cuenta.
        </p>
        {/* Botón de acción */}
        <button
          onClick={onClose} // Cierra el modal al hacer clic en el botón
          className="w-full bg-gradient-to-r from-red-600 to-red-500 hover:from-red-700 hover:to-red-600 text-white font-bold py-3 px-6 rounded-full transition-all duration-300 text-lg"
        >
          Aceptar
        </button>
        {/* Footer adicional con ícono de WhatsApp */}
        <p className="mt-4 text-lg text-gray-500 flex items-center"> {/* Agrandamos el texto */}
          Si necesitas ayuda, llama a nuestro WhatsApp{" "}
          <a
            href="https://wa.me/595983838287"
            className="text-blue-500 hover:underline flex items-center ml-2"
          >
            <FontAwesomeIcon
              icon={faWhatsapp}
              className="text-green-500 text-xl mr-2" // Agrandamos el ícono de WhatsApp
            />
            +595 983 838 287
          </a>
        </p>
      </div>
    </div>
  );
};

export default PaymentAlert;
