import React, { useEffect, useState } from 'react';

const InvoiceStatusManager = ({ sale, companyId }) => {
  const [invoiceStatus, setInvoiceStatus] = useState("Pendiente");

  useEffect(() => {
    if (companyId === 99 && sale?.createdAt) {
      const updateStatus = () => {
        const saleDate = new Date(sale.createdAt);
        const currentDate = new Date();
        const minutesDiff = Math.floor((currentDate - saleDate) / (1000 * 60));

        if (minutesDiff >= 63) {
          setInvoiceStatus("Aprobado");
        } else if (minutesDiff >= 61) {
          setInvoiceStatus("Enviado");
        } else {
          setInvoiceStatus("Pendiente");
        }
      };

      // Actualizar estado inicial
      updateStatus();

      // Actualizar cada 30 segundos
      const interval = setInterval(updateStatus, 30000);

      return () => clearInterval(interval);
    }
  }, [sale?.createdAt, companyId]);

  const getStatusColor = (status) => {
    switch (status) {
      case "Aprobado":
        return "text-green-600 font-semibold";
      case "Enviado":
        return "text-blue-600 font-semibold";
      case "Pendiente":
        return "text-yellow-600 font-semibold";
      default:
        return "";
    }
  };

  return (
    <td className={`text-center ${getStatusColor(invoiceStatus)}`}>
      {invoiceStatus}
    </td>
  );
};

export default InvoiceStatusManager;